.video-player {
    position: relative;
  }
  
  .video {
    width: 100%;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }
  
  .controls button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 10px;
  }
  
  .controls button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  