.video-player {
  background-color: #000;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.plyr {
  height: 100vh !important;
}

.plyr__controls__item--episodes,
.episode-dropdown li,
.season-list li,
.episode-list li {
  cursor: pointer;
  color: white;
}

.plyr__controls__item--episodes {
  margin: 0 10px;
}

.episode-dropdown {
  position: absolute;
  top: 40px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  display: none;
}

.episode-dropdown.show {
  display: block;
}

.episode-dropdown ul,
.season-list ul,
.episode-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.episode-dropdown li,
.season-list li,
.episode-list li {
  padding: 10px;
  border-bottom: 1px solid #555;
}

.episode-dropdown li:hover,
.season-list li:hover,
.episode-list li:hover {
  background-color: #444;
}

.episode-modal {
  position: absolute;
  bottom: 50px;
  right: 7rem;
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
  width: 260px;
  max-height: 300px;
  overflow-y: auto;
}

.episode-list button {
  background: none;
  border: none;
  color: white;
  margin-bottom: 10px;
}

/* Highlight the active episode */
.active-episode {
  background-color: #444;
  color: #fff;
  font-weight: bold;
}

/* Hover effect for custom buttons */
.plyr__control.custom-skip-next-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

/* Tooltip styles */
.custom-skip-next-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  top: -30px; /* Position the tooltip above the button */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show tooltip on hover */
.plyr__control.custom-skip-next-button:hover .custom-skip-next-tooltip {
  opacity: 1;
}

/* Tooltip styles specific to the "Skip Next" button */
.custom-skip-next-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  top: -30px; /* Position the tooltip above the button */
  opacity: 0;
  transition: opacity 0.3s;
}

.top-navigation {
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1; 
  visibility: visible;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.top-navigation.hidden {
  opacity: 0;
  visibility: hidden;
}

.video-player .top-navigation {
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
  visibility: visible;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.video-player .top-navigation.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Add other necessary styles here */
