.data-play {
  padding: 9px 30px;
  border-radius: 10px !important;
  cursor: pointer;
  background: var(--iq-primary-hover);
  border: 1px solid var(--iq-primary-hover);
  border-radius: 5px;
  bottom: 10px;
  box-shadow: 0 0 1px #0000;
  color: var(--iq-white);
  cursor: pointer;
  left: 20px;
  position: absolute !important;
  transform: perspective(1px) translateZ(0);
  transition: color .3s ease;
  vertical-align: middle;
}

.movie-card-overlay {
  position: fixed;
  top: 2.75rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.movie-card {
  background-color: rgba(47, 0, 47, 0.91);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  width: 60%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

@media (max-width: 900px) {
  .movie-card {
    width: 90%;
  }
  .left-details {
    margin-right: 0 !important;
    margin-bottom: 1rem;
    width: 100%;
  }
  .video-container {
    height: 40vh !important;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid white;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  padding: 1px 12px;
  border-radius: 50%;
  transition: background 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.9);
  transform: scale(1.1);
}

.video-container {
  position: relative;
  height: 55vh;
}

.movie-video {
  width: 100%;
  height: 55vh;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.mute-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 2px solid white;
  padding: 15px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.mute-button i {
  font-size: 20px;
}

.mute-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.movie-content {
  padding: 20px;
}

.movie-title {
  color: #fff;
  font-size: 28px;
  margin-bottom: 20px;
}

.movie-description {
  color: #fff;
  text-align: justify;
  margin-bottom: 20px;
}

.movie-info {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

@media (max-width: 900px) {
  .movie-info {
    flex-direction: column;
    align-items: center; /* Optional: center the items */
  }
}

.left-details,
.right-details {
  flex: 1;
  background-color: #ffffff30;
  padding: 1rem;
  border-radius: 5px;
}

.left-details {
  margin-right: 20px;
}

.left-details p,
.right-details p {
  margin: 10px 0;
  font-size: 18px;
}

h1,
p {
  color: #fff;
}

.imdb-badge {
  display: inline-block;
  background-color: #f5c518;
  color: #000;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
}

.related-movies {
  margin-top: 20px;
}

.related-movies h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
}

.similar-movies-title {
  font-size: 1.2rem; /* Adjust size as needed */
  text-align: center;
  margin-bottom: 10px; /* Add margin to separate from slides */
}

.centered-slide {
  display: flex;
  justify-content: center;
}

hr {
  width: 40% !important;
  border: solid 1px #ee6dff !important;
}

.similar-movies h2 {
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.coming_soon {
  height: 15rem;
  font-size: 100%;
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000070;
}

@media (max-width: 900px) {
  .alpha {
    width: 90%;
  }
}

.trailer-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
  color: white;
  background-color: black;
  font-size: 1.5rem;
  text-align: center;
}
