.genre-filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    margin: 20px;
    max-width: 100%; /* Ensure container doesn’t exceed screen width */
  }
  
  .genre-select {
    font-size: 14px;
    color: white;
    margin-left: 60px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    transition: border-color 0.3s, box-shadow 0.3s;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
  }
  
  .genre-select:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .select-label {
    margin-bottom: 8px;
    font-size: 16px; /* Smaller font size */
    font-weight: bold;
    color: #333; /* Improve contrast for readability */
  }
  